@import '~bootstrap/scss/bootstrap';

body {
    // background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)
    background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
    background-attachment: fixed;
}

:root {
    --bs-primary-rgb: #537895;
    --primary: #537895;
    --bs-btn-bg: #09203f;
    --bs-btn-hover-color: #09203f;
}

.btn-primary {
    --bs-btn-bg: #537895;
    --bs-btn-hover-bg: hsl(200, 40%, 40%);
    --bs-btn-border-color: #537895; 
}

.btn-outline-primary {
    --bs-btn-color: #537895;
    --bs-btn-border-color: #537895;
    --bs-btn-hover-bg: #537895;
    --bs-btn-active-bg: #537895;
    --bs-btn-active-border-color: #537895;
    --bs-btn-disabled-color: #537895;
    --bs-btn-disabled-border-color: #537895;

}

.btn-link {
    --bs-btn-color: #537895;
    --bs-btn-hover-color: hsl(200, 40%, 40%);
    --bs-btn-active-color: hsl(200, 40%, 40%);

}

.card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
    background-color: #f0f0f0;
    border: none;
    border-radius: var(--bs-card-border-radius);
}

.card-image-overlay {
    border-radius: var(--bs-card-border-radius);
    overflow: hidden;
}

.header-branding {
    background-image: linear-gradient(to top, #2f4c71 0%, #537895 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.header-container {
    background-color: #f0f0f0;
    z-index: 1000;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

.custom-search-bar {
    display: flex;
    align-items: center;
    margin: 10px auto;
    position: relative;
}

.custom-search-field {
    padding-left: 40px;
    border-radius: 20px;
}

.custom-search-icon {
    position: absolute;
    left: 15px;
    color: #555;
}

.text-jamatkhana-blue {
    color: #537895;
}

.form-label {
    margin-top: 0.5rem;
    margin-bottom: 0;
}